import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {TaskList} from "./TaskList";
import {ChecklistTaskItem, TaskItem} from "./TaskItem";
import localforage from "localforage";

export const ProfileTasksKey = "ProfileTasks";

export function useProfilesLists(selectedProfile: string){
    return useQuery([ProfileTasksKey, selectedProfile], async () => {
        const storageItem = await localforage.getItem<string>(ProfileTasksKey+"_"+selectedProfile);
        if(!storageItem)
            return [] as TaskList[];

        return JSON.parse(storageItem) as TaskList[];
    },
        {enabled: !!selectedProfile});
}

export function useChecklistTasks({selectedProfile, listId, taskId }: {selectedProfile: string, listId: string, taskId: string}){
    return useQuery([ProfileTasksKey, selectedProfile, listId, taskId], async () => {
            const storageItem = await localforage.getItem<string>(ProfileTasksKey+"_"+selectedProfile);
            if(!storageItem)
                return [] as ChecklistTaskItem[];

            const profileLists = JSON.parse(storageItem) as TaskList[];
            const listIndex = profileLists.findIndex(x => x.Id === listId);
            if(listIndex < 0)
                return [] as ChecklistTaskItem[];

            const list = profileLists[listIndex];
            const taskIndex = list.TaskItems?.findIndex(x => x.Id === taskId);
            if(taskIndex === undefined || taskIndex < 0)
                return [] as ChecklistTaskItem[];

            const task = list.TaskItems[taskIndex];

            return task.ChecklistSubItems;
        },
        {enabled: !!selectedProfile});
}

export function useAddListForProfile(selectedProfile: string){
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {newListName: string}) => {
            const {newListName} = variables;
            const existingListsJson = await localforage.getItem<string>(ProfileTasksKey+"_"+selectedProfile);
            const existingLists = existingListsJson ? JSON.parse(existingListsJson) as TaskList[] : [];
            if(existingLists.some(x => x.Name === newListName))
                return;
            const newTaskList = new TaskList({Name: newListName});
            const newLists = existingLists ? [...existingLists, newTaskList] : [newTaskList];
            console.log("Just added new Task List with ID: ", newTaskList.Id);
            await localforage.setItem(ProfileTasksKey+"_"+selectedProfile, JSON.stringify(newLists));
            await queryClient.invalidateQueries([ProfileTasksKey, selectedProfile])
        },
        // When mutate is called:
        onMutate: async (variables) => {
            const {newListName} = variables;
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: [ProfileTasksKey, selectedProfile] })

            //can't be bothered passing tasklist in, basically this plus the mutationFn both call new TaskList with just the name so different ids.
            // await queryClient.invalidateQueries({ queryKey: [ProfileTasksKey+"_"+selectedProfile] })

            // Optimistically update to the new value
            /*queryClient.setQueryData<TaskList[]>([ProfileTasksKey, selectedProfile], old => {
                const newTaskList = new TaskList({Name: newListName});
                if(!old)
                    return [newTaskList];
                if(old.some(x => x.Name === newListName)){
                    return old;
                }

                return [...old, newTaskList];
            });*/
        },
    });
}

export function useSetListsForProfile(selectedProfile: string){
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (newLists: TaskList[]) => {
            await localforage.setItem(ProfileTasksKey+"_"+selectedProfile, JSON.stringify(newLists));
        },
        // When mutate is called:
        onMutate: async (newLists) => {
            // Optimistically update to the new value
            queryClient.setQueryData<TaskList[]>([ProfileTasksKey, selectedProfile], old => {
                return [...newLists]
            });
        },
    });
}
export function useSetListName(selectedProfile: string, listId: string){
    // const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (newListName: string) => {
            const existingListsJson = await localforage.getItem<string>(ProfileTasksKey+"_"+selectedProfile);
            const existingLists = existingListsJson ? JSON.parse(existingListsJson) as TaskList[] : [];
            if(!existingLists)
                return;

            const listIndex = existingLists.findIndex(x => x.Id === listId);
            if(listIndex < 0)
                return;
            existingLists[listIndex].Name = newListName;
            await localforage.setItem(ProfileTasksKey+"_"+selectedProfile, JSON.stringify(existingLists));
        },
        // When mutate is called:
        /*onMutate: async (newLists) => {
            // Optimistically update to the new value
            queryClient.setQueryData<TaskList[]>([ProfileTasksKey, selectedProfile], old => {
                return [...newLists]
            });
        },*/
    });
}

export function useDeleteListFromProfile(selectedProfile: string){
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (listId: string) => {
            const existingListsJson = await localforage.getItem<string>(ProfileTasksKey+"_"+selectedProfile);
            const existingLists = existingListsJson ? JSON.parse(existingListsJson) as TaskList[] : [];
            await localforage.setItem(ProfileTasksKey+"_"+selectedProfile, JSON.stringify(existingLists.filter(x => x.Id !== listId)));
        },
        // When mutate is called:
        onMutate: async (listId) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: [ProfileTasksKey, selectedProfile] })

            // Optimistically update to the new value
            queryClient.setQueryData<TaskList[]>([ProfileTasksKey, selectedProfile], old => {
                if(!old)
                    return [];
                const foundList = old.find(x => x.Id === listId);
                if(!foundList)
                    return old;
                return [...old.filter(x => x.Id !== listId)];
            });
        },
    });
}

export function useAddItemForList(selectedProfile: string){
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {newTask: TaskItem, listId: string}) => {
            const {newTask, listId} = variables;
            const existingListsJson = await localforage.getItem<string>(ProfileTasksKey+"_"+selectedProfile);
            const existingLists = existingListsJson ? JSON.parse(existingListsJson) as TaskList[] : [];
            const foundList = existingLists.find(x => x.Id === listId);
            if(!foundList) {
                alert("couldn't find list to add to for list id: " + listId + " existing lists count: " + existingLists.length);
                return;
            }
            const foundItemIndex = foundList.TaskItems.findIndex(x => x.Id === newTask.Id);
            if(foundItemIndex >= 0){
                foundList.TaskItems[foundItemIndex] = new TaskItem(newTask);
            }
            else
                foundList.TaskItems.push(newTask);
            await localforage.setItem(ProfileTasksKey+"_"+selectedProfile, JSON.stringify(existingLists));
        },
        // When mutate is called:
        onMutate: async (variables) => {
            const {newTask, listId} = variables;
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: [ProfileTasksKey, selectedProfile] })

            // Optimistically update to the new value
            queryClient.setQueryData<TaskList[]>([ProfileTasksKey, selectedProfile], old => {
                if(!old)
                    return [];
                const foundList = new TaskList(old.find(x => x.Id === listId));
                if(!foundList){
                    return old;
                }
                const foundItemIndex = foundList.TaskItems.findIndex(x => x.Id === newTask.Id);
                foundList.TaskItems = [...foundList.TaskItems]
                if(foundItemIndex >= 0)
                    foundList.TaskItems[foundItemIndex] = newTask;
                else
                    foundList.TaskItems.push(newTask);
                old[old.findIndex(x => x.Id === listId)] = foundList;
                // console.log("Setting", ProfileTasksKey, selectedProfile, "to:", old);
                return [...JSON.parse(JSON.stringify(old))];
            });
        },
        onError: () => {
            alert("error")
        }
    });
}

export function useAddOrUpdateChecklistForItem(selectedProfile: string){
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {taskId: string, listId: string, index?: number, checklistTask: ChecklistTaskItem}) => {
            const {taskId, listId, checklistTask, index} = variables;
            const existingListsJson = await localforage.getItem<string>(ProfileTasksKey+"_"+selectedProfile);
            const existingLists = existingListsJson ? JSON.parse(existingListsJson) as TaskList[] : [];
            const foundList = existingLists.find(x => x.Id === listId);
            if(!foundList)
                return;
            const foundItemIndex = foundList.TaskItems.findIndex(x => x.Id === taskId);
            if(foundItemIndex >= 0){
                const existingIndex = foundList.TaskItems[foundItemIndex].ChecklistSubItems?.findIndex(x => x.Id === checklistTask.Id) ?? -1;
                if(existingIndex >= 0) {
                    foundList.TaskItems[foundItemIndex].ChecklistSubItems![existingIndex] = checklistTask;
                }
                else {
                    if(index !== undefined && index >= 0) {
                        foundList.TaskItems[foundItemIndex].ChecklistSubItems = [...(foundList.TaskItems[foundItemIndex].ChecklistSubItems!)];
                        foundList.TaskItems[foundItemIndex].ChecklistSubItems!.splice(index+1, 0, checklistTask);
                    }
                    else
                        foundList.TaskItems[foundItemIndex].ChecklistSubItems = [...foundList.TaskItems[foundItemIndex].ChecklistSubItems ?? [], checklistTask];
                }
            }
            await localforage.setItem(ProfileTasksKey+"_"+selectedProfile, JSON.stringify(existingLists));
        },
        // When mutate is called:
        onMutate: async (variables) => {
            const {taskId, listId, checklistTask, index} = variables;
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: [ProfileTasksKey, selectedProfile, listId, taskId] })

            // Optimistically update to the new value
            queryClient.setQueryData<ChecklistTaskItem[]>([ProfileTasksKey, selectedProfile, listId, taskId], old => {
                if(!old)
                    return [];

                let copy = [...old];
                const existingIndex = old.findIndex(x => x.Id === checklistTask.Id) ?? -1;
                if(existingIndex >= 0)
                    copy[existingIndex] = checklistTask;
                else {
                    if(index !== undefined && index >= 0) {
                        console.log("Adding new checklist to index ", index+1)
                        copy.splice(index+1, 0, checklistTask);
                    }
                    else
                        copy = [...copy, checklistTask];
                }

                // copy[copy.findIndex(x => x.Id === listId)] = foundList;
                return [...JSON.parse(JSON.stringify(copy))];
            });
        },
        onSettled: async () => await queryClient.invalidateQueries({ queryKey: [ProfileTasksKey, selectedProfile] })
    });
}

export function useDeleteChecklistFromItem(selectedProfile: string){
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {taskId: string, listId: string, checklistId: string}) => {
            const {taskId, listId, checklistId} = variables;
            const existingListsJson = await localforage.getItem<string>(ProfileTasksKey+"_"+selectedProfile);
            const existingLists = existingListsJson ? JSON.parse(existingListsJson) as TaskList[] : [];
            const foundList = existingLists.find(x => x.Id === listId);
            if(!foundList)
                return;
            const foundItemIndex = foundList.TaskItems.findIndex(x => x.Id === taskId);
            if(foundItemIndex >= 0){
                const existingIndex = foundList.TaskItems[foundItemIndex].ChecklistSubItems?.findIndex(x => x.Id === checklistId) ?? -1;
                if(existingIndex >= 0)
                    foundList.TaskItems[foundItemIndex].ChecklistSubItems!.splice(existingIndex, 1);
            }
            await localforage.setItem(ProfileTasksKey+"_"+selectedProfile, JSON.stringify(existingLists));
        },
        // When mutate is called:
        onMutate: async (variables) => {
            const {taskId, listId, checklistId} = variables;
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: [ProfileTasksKey, selectedProfile, listId, taskId] })

            // Optimistically update to the new value
            queryClient.setQueryData<ChecklistTaskItem[]>([ProfileTasksKey, selectedProfile, listId, taskId], old => {
                if(!old)
                    return [];

                return [...JSON.parse(JSON.stringify(old.filter(x => x.Id !== checklistId)))];
            });
        },
    });
}

export function useDeleteItemFromList(selectedProfile: string){
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {taskId: string, listId: string}) => {
            const {taskId, listId} = variables;
            const existingListsJson = await localforage.getItem<string>(ProfileTasksKey+"_"+selectedProfile);
            const existingLists = existingListsJson ? JSON.parse(existingListsJson) as TaskList[] : [];
            const foundList = existingLists.find(x => x.Id === listId);
            if(!foundList)
                return;
            const foundItemIndex = foundList.TaskItems.findIndex(x => x.Id === taskId);
            if(foundItemIndex >= 0)
                foundList.TaskItems.splice(foundItemIndex, 1);
            await localforage.setItem(ProfileTasksKey+"_"+selectedProfile, JSON.stringify(existingLists));
            // await queryClient.invalidateQueries({ queryKey: [ProfileTasksKey, selectedProfile] })
        },
        // When mutate is called:
        onMutate: async (variables) => {
            const {taskId, listId} = variables;
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: [ProfileTasksKey, selectedProfile] })
            // await queryClient.invalidateQueries({ queryKey: [ProfileTasksKey, selectedProfile] })

            // Optimistically update to the new value
            queryClient.setQueryData<TaskList[]>([ProfileTasksKey, selectedProfile], old => {
                if(!old)
                    return [];
                const foundList = new TaskList(old.find(x => x.Id === listId));
                if(!foundList)
                    return old;
                foundList.TaskItems = [...foundList.TaskItems.filter(x => x.Id !== taskId)]
                old[old.findIndex(x => x.Id === listId)] = foundList;
                // console.log("Deleted task, now: ", foundList.TaskItems)
                return [...JSON.parse(JSON.stringify(old))];
            });
        },
    });
}
