import React from 'react';
import {MDBCard} from "mdb-react-ui-kit";
import TaskCardHeader from "./TaskCardHeader";
import TaskCardBody from "./TaskCardBody";
import {TaskList} from "../../Data/TaskList";
import {TaskItem} from "../../Data/TaskItem";
import {Draggable} from "react-beautiful-dnd";
import styled from "styled-components";

interface ITaskCardProps {
    selectedProfile: string
    list: TaskList
    task: TaskItem
    index: number
}

interface Item {
    id: string
    originalIndex: number
    height: number
}

const Container = styled.div<{$isDragging: boolean}>`
  position: relative;
  margin-top: .25rem;
  margin-bottom: .25rem;
  outline: none;
  border-radius: 5px;
  //box-shadow:  ${(props) => props.$isDragging ? "0 0 0 2pt rgba(200, 200, 230, 1);" : "auto"};
`;

const TaskCard: React.FC<ITaskCardProps> = ({selectedProfile, list, task, index}) => {
    const taskHasDetails = (task.Description && task.Description.length > 0) || (task.ChecklistSubItems && task.ChecklistSubItems.length > 0);

    return (
        <Draggable draggableId={task.Id} index={index}>
            {(provided, snapshot) => (
                <Container
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    $isDragging={snapshot.isDragging}
                >
                    <MDBCard alignment='center' className={"card-container"}>
                        <TaskCardHeader selectedProfile={selectedProfile} list={list} task={task}/>
                        {taskHasDetails ? <TaskCardBody selectedProfile={selectedProfile} list={list} task={task}/> : null}
                    </MDBCard>
                </Container>
            )}
        </Draggable>
    );

    /*return (
        <div ref={(node) => {
            divRef.current = node;
            drag(drop(node));
        }} style={{ opacity }}>
            <MDBCard alignment='center' className={"card-container"}>
                <TaskCardHeader selectedProfile={selectedProfile} list={list} task={task}/>
                {taskHasDetails ? <TaskCardBody selectedProfile={selectedProfile} list={list} task={task}/> : null}
            </MDBCard>
        </div>
    );*/
};

export default TaskCard;