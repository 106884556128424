import React from 'react';
import {useDeleteChecklistFromItem, useDeleteItemFromList, useDeleteListFromProfile} from "../../Data/useProfilesLists";
import DeleteBtn from "../DeleteBtn";

interface IDeleteChecklistCardBtnProps {
    selectedProfile: string
    listId: string,
    taskId: string
    checklistId: string
}

const DeleteChecklistCardBtn: React.FC<IDeleteChecklistCardBtnProps> = ({selectedProfile, listId, taskId, checklistId}) => {
    const {mutateAsync: deleteChecklist} = useDeleteChecklistFromItem(selectedProfile);

    const handleButtonClicked = async () => {
        await deleteChecklist({listId, taskId, checklistId});
    }

    return (
        <DeleteBtn containerClassName={"checklist-child"} onClick={handleButtonClicked} containerStyle={{top: "-4px"}}/>
    );
};

export default DeleteChecklistCardBtn;