import {Crypt} from "../../../Helper/Crypt";

export class TaskItem {
    public Id: string = Crypt.GetUID()
    public Name: string = ""
    public Description?: string
    public ChecklistSubItems?: ChecklistTaskItem[]

    constructor(obj?: Partial<TaskItem>) {
        if (obj)
            Object.assign(this, obj);
    }
}

export class ChecklistTaskItem {
    public Id: string = Crypt.GetUID()
    public Name: string = ""
    public Checked: boolean = false

    constructor(obj?: Partial<TaskItem>) {
        if (obj)
            Object.assign(this, obj);
    }
}