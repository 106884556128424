import React, {CSSProperties} from 'react';
import {MDBBtn, MDBIcon} from "mdb-react-ui-kit";
import {ButtonProps} from "mdb-react-ui-kit/dist/types/free/components/Button/types";

interface IDeleteBtnProps extends ButtonProps {
    containerClassName: string
    containerStyle?: CSSProperties
}

const ContainerStyle: CSSProperties = {
    justifyContent: "flex-end",
    position: "absolute",
    top: 3,
    right: 3,
    zIndex: 3
}

const ButtonStyle: CSSProperties = {
    width: "20px",
    height: "20px"
}

const IconStyle: CSSProperties = {
    width: "15px",
    height: "5px",
    fontSize: "0.9em",
    lineHeight: 2
}

const DeleteBtn: React.FC<IDeleteBtnProps> = ({containerClassName, containerStyle, ...rest}) => {

    return (
        <div className={containerClassName} style={{...ContainerStyle, ...containerStyle}} >
            <MDBBtn floating tag='a' size={"sm"} color={"secondary"} style={ButtonStyle} {...rest}>
                <MDBIcon fas size={"xs"} icon='times' style={IconStyle} />
            </MDBBtn>
        </div>
    );
};

export default DeleteBtn;