import {Crypt} from "../../../Helper/Crypt";

export class TaskProfile {
    public Id: string = Crypt.GetUID()
    public Name: string = "My Tasks"
    public Archived: boolean = false

    constructor(obj?: Partial<TaskProfile>, name?: string) {
        if (obj)
            Object.assign(this, obj);

        if(typeof this.Name !== "string")
            this.Name = "My Tasks";

        if(name && typeof name === "string")
            this.Name = name;
    }
}