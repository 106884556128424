import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {MDBCheckbox} from "mdb-react-ui-kit";
import {ChecklistTaskItem} from "../../Data/TaskItem";
import {useAddOrUpdateChecklistForItem, useDeleteChecklistFromItem} from "../../Data/useProfilesLists";
import DeleteChecklistCardBtn from "./DeleteChecklistCardBtn";
import OverlayTextArea from "../../../../CommonComponents/OverlayTextArea";
import WrappedSingleLineTextArea from "../../../../CommonComponents/WrappedSingleLineTextArea";

interface ISubItemCheckboxProps {
    selectedProfile: string
    listId: string
    taskId: string
    subTask: ChecklistTaskItem
    index: number
}

const CheckboxContainerStyle: CSSProperties = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    marginTop: "1px",
    marginBottom: "1px"
}

const CheckboxInputStyle: CSSProperties = {
    width: "100%",
    lineHeight: "normal",
    border: "none",
    borderBottom: ".05rem solid rgba(0,0,0,.25)",
    height: "1.2rem",
    marginTop: "-3px",
    fontSize: ".8em",
    color: "rgb(87 86 86)"
}

const SubItemCheckbox: React.FC<ISubItemCheckboxProps> = ({selectedProfile, listId, taskId, subTask, index}) => {
    const {mutateAsync: addOrUpdateChecklistItem} = useAddOrUpdateChecklistForItem(selectedProfile);
    const {mutateAsync: deleteChecklistFromItem} = useDeleteChecklistFromItem(selectedProfile);
    const [text, setText] = useState(subTask.Name ?? "")

    const handleCheckboxChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        subTask.Checked = event.target.checked;
        await addOrUpdateChecklistItem({listId: listId, taskId: taskId, checklistTask: subTask});
    }

    const handleCheckboxInputBlur = async (event?: React.FocusEvent<HTMLTextAreaElement>) => {
        if(!event)
            return;

        if (event.target.value && event.target.value.length > 0) {
            subTask.Name = event.target.value;
            await addOrUpdateChecklistItem({listId: listId, taskId: taskId, checklistTask: subTask});
        } else {
            await deleteChecklistFromItem({
                taskId: taskId,
                listId: listId,
                checklistId: subTask.Id
            });
        }
    }

    const handleEnterPressed = async(value: string) => {
        console.log("index for enter", index)
        // alert("pressed enter for: " + index)
        if(value.length > 0) {
            subTask.Name = value;
            await addOrUpdateChecklistItem({listId: listId, taskId: taskId, checklistTask: subTask});
            await addOrUpdateChecklistItem({
                listId,
                taskId,
                index,
                checklistTask: new ChecklistTaskItem()
            });
        }
        else
            await deleteChecklistFromItem({
                taskId: taskId,
                listId: listId,
                checklistId: subTask.Id
            });
    }

    return (
        <div style={CheckboxContainerStyle} className={"checkbox-container checklist-container"}>
            <MDBCheckbox name='checkNoLabel' id='checkboxNoLabel' defaultChecked={subTask.Checked} value='' aria-label='...' onChange={handleCheckboxChanged}/>
            <WrappedSingleLineTextArea
                text={text}
                setText={setText}
                // defaultValue={subTask.Name}
                autoFocus={!subTask.Name}
                onBlur={handleCheckboxInputBlur}
                onEnterPressed={handleEnterPressed}
                style={CheckboxInputStyle}
            />
            <DeleteChecklistCardBtn selectedProfile={selectedProfile} listId={listId} taskId={taskId} checklistId={subTask.Id}/>
        </div>
    );
};

export default SubItemCheckbox;