import React, {HTMLAttributes, HTMLProps, PropsWithChildren, ReactElement, useRef, useState} from 'react';
import styled from "styled-components";

interface IOverlayTextAreaProps extends Pick<HTMLProps<HTMLDivElement>, "style">{
    focusInput: () => void
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement> | undefined
    height?: number
}

const Container = styled.div`
  position: relative;
  line-height: 1;
`
const Overlay = styled.div<{$overlayVisible: boolean, $height?: number | undefined}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  line-height: 1;
  height: ${(props) => props.$height + "px" ?? '100%'};
  display: ${(props) => props.$overlayVisible ? "initial" : "none"};
`

const OverlayTextArea: React.FC<PropsWithChildren<IOverlayTextAreaProps>> = ({children, focusInput, onBlur, height, style}) => {

    const [overlayVisible, setOverlayVisible] = useState(true);
    const handleBlur = (event: any) => {
        setOverlayVisible(true);
        if(onBlur)
            onBlur(event);
    };

    const focusChildInput = () => {
        focusInput();
        setOverlayVisible(false);
    };

    return (
        <div style={{position: 'relative', lineHeight: 1, ...style}}>
            {React.Children.map(children, (child) => React.cloneElement(child as ReactElement, { onBlur: handleBlur }))}
            <Overlay onClick={focusChildInput} $overlayVisible={overlayVisible} $height={height}/>
        </div>
    );
};

export default OverlayTextArea;