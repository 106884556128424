import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import * as localforage from "localforage";
import {SelectedProfileQueryKey} from "./useSelectedProfile";
import {ProfileTasksKey} from "../../Tasks/Data/useProfilesLists";
import {TaskProfile} from "../../Tasks/Data/TaskProfile";

export const ProfilesQueryKey = "Profiles";

export async function migrateLocalStorage(){
    const existingProfiles = await localforage.getItem<string>(ProfilesQueryKey);
    if(existingProfiles)
        return false;
    const storageItem = await localStorage.getItem(ProfilesQueryKey);
    if(!storageItem)
        return false;
    await localforage.setItem<string>(ProfilesQueryKey, storageItem);
    const selectedProfile = await localStorage.getItem(SelectedProfileQueryKey);
    if(selectedProfile)
        await localforage.setItem<string>(SelectedProfileQueryKey, selectedProfile);

    const profiles = JSON.parse(storageItem) as string[];
    for (let profile of profiles) {
        const existingListsJson = await localStorage.getItem(ProfileTasksKey+"_"+profile);
        if(existingListsJson)
            await localforage.setItem<string>(ProfileTasksKey+"_"+profile, existingListsJson);
    }
    return true;
}

export function useProfiles(){
    return useQuery<TaskProfile[]>([ProfilesQueryKey], async () => {
        const storageItem = await localforage.getItem<string>(ProfilesQueryKey);
        if(!storageItem) {
            const defaultProfiles = [new TaskProfile()];
            await localforage.setItem(ProfilesQueryKey, JSON.stringify(defaultProfiles));
            return defaultProfiles;
        }
        let profiles: TaskProfile[]
        const oldProfiles = JSON.parse(storageItem) as string[]
        if(Array.isArray(oldProfiles) && typeof oldProfiles[0] === "string"){
            alert("old profile is array and going to convert to new way with TaskProfile Object")
            profiles = oldProfiles.map(x => new TaskProfile({}, x));
            await localforage.setItem<string>(ProfilesQueryKey, JSON.stringify(profiles));
        } else {
            profiles = JSON.parse(storageItem) as TaskProfile[];
        }
        return profiles;
    });
}


export function useAddProfile(){
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (newProfileName: string) => {
            const existingProfiles = await localforage.getItem<string>(ProfilesQueryKey);
            const newTaskProfile = new TaskProfile({}, newProfileName);
            const newProfiles = existingProfiles ? [...JSON.parse(existingProfiles) as TaskProfile[], newTaskProfile] : [newTaskProfile];
            await localforage.setItem(ProfilesQueryKey, JSON.stringify(newProfiles));
        },
        // When mutate is called:
        onMutate: async (variables) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: [ProfilesQueryKey] })

            // Optimistically update to the new value
            queryClient.setQueryData<TaskProfile[]>([ProfilesQueryKey], old => {
                if(!old)
                    return [new TaskProfile({}, variables)];
                return [...old, new TaskProfile({}, variables)];
            });
        },
    });
}


export function useArchiveProfile(){
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({profileName, archive}: {profileName: string, archive: boolean}) => {
            const existingProfiles = await localforage.getItem<string>(ProfilesQueryKey);
            if(!existingProfiles)
                return;
            const profiles = JSON.parse(existingProfiles) as TaskProfile[];
            const profileIndex = profiles.findIndex(x => x.Name === profileName);
            profiles[profileIndex].Archived = archive;
            await localforage.setItem(ProfilesQueryKey, JSON.stringify(profiles));
        },
        // When mutate is called:
        onMutate: async (variables) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: [ProfilesQueryKey] })

            // Optimistically update to the new value
            queryClient.setQueryData<TaskProfile[]>([ProfilesQueryKey], old => {
                if(!old)
                    return [];
                const profiles = [...old];
                const profileIndex = profiles.findIndex(x => x.Name === variables.profileName);
                profiles[profileIndex].Archived = variables.archive;
                return profiles;
            });
        },
    });
}

export function useDeleteProfile(){
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (profileName: string) => {
            const existingProfiles = await localforage.getItem<string>(ProfilesQueryKey);
            if(!existingProfiles)
                return;
            const profiles = JSON.parse(existingProfiles) as TaskProfile[];
            const profileIndex = profiles.findIndex(x => x.Name === profileName);
            profiles.splice(profileIndex, 1);
            await localforage.setItem(ProfilesQueryKey, JSON.stringify(profiles));
        },
        // When mutate is called:
        onMutate: async (profileName: string) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: [ProfilesQueryKey] })

            // Optimistically update to the new value
            queryClient.setQueryData<TaskProfile[]>([ProfilesQueryKey], old => {
                if(!old)
                    return [];
                const profiles = [...old];
                const profileIndex = profiles.findIndex(x => x.Name === profileName);
                profiles.splice(profileIndex, 1);
                return profiles;
            });
        },
    });
}