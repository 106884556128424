import React from 'react';
import {MDBBtn} from "mdb-react-ui-kit";
import {useAddItemForList} from "../../Data/useProfilesLists";
import {TaskItem} from "../../Data/TaskItem";

interface IAddTaskItemBtnProps {
    selectedProfile: string
    listId: string
    taskItemsCount: number
}

const AddTaskItemBtn: React.FC<IAddTaskItemBtnProps> = ({selectedProfile, listId, taskItemsCount}) => {
    const {mutateAsync: addItem} = useAddItemForList(selectedProfile);

    const handleAddItemForList = async () => {
        const newTask = new TaskItem();
        newTask.Name = "_New Item #" + (taskItemsCount + 1);
        await addItem({newTask, listId})
    }
    return (
        <MDBBtn style={{marginTop: ".25rem"}} color='secondary' onClick={handleAddItemForList}>
            Add Item
        </MDBBtn>
    );
};

export default AddTaskItemBtn;