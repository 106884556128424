import {TaskItem} from "./TaskItem";
import {Crypt} from "../../../Helper/Crypt";

export class TaskList {
    public Id: string = "";
    public Name: string = ""
    public Colour: string = ""
    public TaskItems: TaskItem[] = []

    constructor(obj?: Partial<TaskList>) {
        if (obj){
            const {TaskItems, ...taskList} = obj;
            Object.assign(this, {...taskList});
            if(TaskItems){
                for (const taskItem of TaskItems) {
                    this.TaskItems.push(new TaskItem({...taskItem}));
                }
            }
        }
        if(this.Id.length === 0) {
            this.Id = Crypt.GetUID();
        }
    }

    public static CreateTaskLists(objs?: Partial<TaskList>[]): TaskList[] {
        if (objs){
            for (let obj of objs) {
                const {TaskItems, ...taskList} = obj;
                const newTaskList = new TaskList(obj);
                if(TaskItems){
                    for (const taskItem of TaskItems) {
                        newTaskList.TaskItems.push(new TaskItem(taskItem));
                    }
                }
            }
        }
        return [];
    }
}